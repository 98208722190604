import { Link } from 'gatsby';
import React from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './card.module.scss';
import { CallToActionCardProps } from './models';

export default function CallToActionCard(props: StylableProp<CallToActionCardProps>) {
  const contents = (
    <>
      <h3 className={'text-center'}>{props.title}</h3>
      <span>{props.actionLabel} &gt;</span>
    </>
  );

  const linkProps = {
    className: `${style.card} ${[
      props.className
    ]} d-flex flex-column align-items-center justify-content-center`
  };

  return props.actionHref.startsWith('/') ? (
    <Link to={props.actionHref} {...linkProps}>
      {contents}
    </Link>
  ) : (
    <a href={props.actionHref} target="_blank" {...linkProps}>
      {contents}
    </a>
  );
}
