import React from 'react';
import PerspectiveCard from '../perspective-card';
import RoundBtn from '../round-btn';
import SectionTitle from '../section-title';
import CallToActionCard from './card';
import * as style from './index.module.scss';
import { CallToActionProps } from './models';

export default function CallToAction(props: CallToActionProps) {
  const cardList: JSX.Element[] = [];

  for (let i = 0; i < props.cards.length; i++) {
    cardList.push(
      <CallToActionCard
        key={props.cards[i].title}
        className="col"
        title={props.cards[i].title}
        actionLabel={props.cards[i].actionLabel}
        actionHref={props.cards[i].actionHref}
      />
    );

    if (i % 2) {
      cardList.push(<div className="w-100" key={`spacer-${i}`}></div>);
    }
  }

  return (
    <section className={style.callToAction + ' overflow-hidden'}>
      <div className={'container'}>
        <header>
          <SectionTitle className="text-md-center">{props.title}</SectionTitle>
        </header>
        {props.actions && props.actions.length && (
          <div
            className={
              style.actions +
              ' d-flex flex-column flex-sm-row align-items-sm-center justify-content-center gap-4'
            }
          >
            {props.actions.map((item) => (
              <PerspectiveCard key={item.label} className={style.perspectiveCard} intensity={25}>
                <RoundBtn
                  className={item.isPrimary ? style.primaryBtn : style.defaultBtn}
                  href={item.href}
                >
                  {item.label}
                </RoundBtn>
              </PerspectiveCard>
            ))}
          </div>
        )}
        <div className={style.cardList + ' row flex-column flex-sm-row'}>{cardList}</div>
      </div>
    </section>
  );
}
